import { useState, useEffect } from "react";
import { buildClientSchema, getIntrospectionQuery } from "graphql";
import { useHeaders } from "../context";
import { fetcher } from "../utils";

export const useIntrospection = () => {
  const { headers, isAuthenticated } = useHeaders();
  const [introspection, setIntrospection] = useState({
    introspecting: false,
    schema: null,
    error: null,
  });

  useEffect(() => {
    const fetchData = () => {
      setIntrospection({
        introspecting: true,
        schema: null,
        error: null,
      });
      fetcher(
        {
          query: getIntrospectionQuery(),
        },
        headers
      ).then(({ data }) => {
        if (data?.errors) {
          setIntrospection({
            introspecting: false,
            schema: null,
            error: data.errors[0].message,
          });
        } else {
          setIntrospection({
            introspecting: false,
            schema: buildClientSchema(data),
            error: null,
          });
        }
      });
    };
    if (isAuthenticated) fetchData();
  }, [headers, isAuthenticated]);

  return introspection;
};
