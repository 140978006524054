import qs from "qs";
import config from "./config";

export const getToken = async (params) =>
  fetch(config.credentials.tokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body:
      params?.grant_type === "password"
        ? qs.stringify({
            grant_type: params?.grant_type,
            username: params?.username,
            password: params?.password,
            client_id: config.credentials.clientId,
          })
        : qs.stringify({
            grant_type: params?.grant_type,
            client_id: params?.client_id,
            client_secret: params?.client_secret,
          }),
  })
    .then((res) => res?.json())
    .then((data) => {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    });

export const fetcher = async (params = {}, headers = []) =>
  fetch(config.graphql.endpoint, {
    method: "POST",
    headers: {
      ...Object.assign(
        {},
        ...headers.map(({ key, value }) => ({
          [key]: value,
        }))
      ),
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.text())
    .then((body) => {
      try {
        return JSON.parse(body);
      } catch (e) {
        return body;
      }
    });
