const HOSTS = {
  local: {
    api: "https://api.staging.intelligencia.ai",
    auth: "https://auth.staging.intelligencia.ai",
  },
  develop: {
    api: "https://api.develop.intelligencia.ai",
    auth: "https://auth.staging.intelligencia.ai",
  },
  staging: {
    api: "https://api.staging.intelligencia.ai",
    auth: "https://auth.staging.intelligencia.ai",
  },
  production: {
    api: "https://api.intelligencia.ai",
    auth: "https://auth.intelligencia.ai",
  },
};

const env =
  window.location.hostname.match(/^[a-z-]+\.local\./i) ||
  window.location.hostname.match("localhost")
    ? "local"
    : window.location.hostname.match(/^[a-z-0-9]+\.develop\./i)
    ? "develop"
    : window.location.hostname.match(/^[a-z-]+\.staging\./i)
    ? "staging"
    : "production";

const configuration = {
  graphql: {
    endpoint: `${HOSTS[env].api}/v1/graphql`,
  },
  credentials: {
    clientId: "insight",
    tokenUrl: `${HOSTS[env].auth}/auth/realms/master/protocol/openid-connect/token`,
  },
};

export default configuration;
