import { Table } from "antd";
import { useHeaders } from "../context";

const COLUMNS = [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    render: (_, record) =>
      record?.value?.startsWith("Bearer") ? (
        <>****************</>
      ) : (
        record?.value
      ),
  },
];

const Headers = () => {
  const { headers } = useHeaders();

  return (
    <Table pagination={false} columns={COLUMNS} dataSource={headers} bordered />
  );
};

export default Headers;
