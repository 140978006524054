import { useState, useRef } from "react";
import GraphiQL from "graphiql";
import GraphiQLExplorer from "graphiql-explorer";
import { fetcher } from "../utils";
import { useHeaders } from "../context";
import { useIntrospection } from "../hooks";

import "graphiql/graphiql.css";

export const PLACEHOLDER = `# Welcome to GraphiQL
#
# GraphiQL is an in-browser tool for writing, validating, and
# testing GraphQL queries.
#
# Type queries into this side of the screen, and you will see intelligent
# typeaheads aware of the current GraphQL type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a "{" character. Lines that starts
# with a # are ignored.
#
# An example GraphQL query might look like:
#
#     {
#       field(arg: "value") {
#         subField
#       }
#     }
#
# Keyboard shortcuts:
#
#  Prettify Query:  Shift-Ctrl-P (or press the prettify button above)
#
#     Merge Query:  Shift-Ctrl-M (or press the merge button above)
#
#       Run Query:  Ctrl-Enter (or press the play button above)
#
#   Auto Complete:  Ctrl-Space (or just start typing)
#
`;

const Graphiql = () => {
  const { headers } = useHeaders();
  const { schema } = useIntrospection();
  const [query, setQuery] = useState(PLACEHOLDER);
  const [open, setOpen] = useState(true);
  const graphiQLRef = useRef(null);

  return (
    <div className="graphiql-container">
      <GraphiQLExplorer
        schema={schema}
        query={query}
        onEdit={setQuery}
        explorerIsOpen={open}
        onRunOperation={(operationName) =>
          graphiQLRef.current.handleRunQuery(operationName)
        }
      />
      <GraphiQL
        ref={graphiQLRef}
        fetcher={(graphQLParams) => fetcher(graphQLParams, headers)}
        schema={schema}
        query={query}
        onEditQuery={setQuery}
        getDefaultScalarArgValue={null}
        makeDefaultArg={null}
      >
        <GraphiQL.Toolbar>
          <GraphiQL.Button
            label="Prettify"
            title="Prettify Query"
            onClick={() => graphiQLRef.current.handlePrettifyQuery()}
          />
          <GraphiQL.Button
            label="History"
            title="Show History"
            onClick={() => graphiQLRef.current.handleToggleHistory()}
          />
          <GraphiQL.Button
            label="Explorer"
            title="Toggle Explorer"
            onClick={() => setOpen(!open)}
          />
        </GraphiQL.Toolbar>
      </GraphiQL>
    </div>
  );
};

export default Graphiql;
