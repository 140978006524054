import { useCallback, useState } from "react";
import { Form, Input, Select, Button, notification } from "antd";
import { getToken } from "../utils";
import { useHeaders } from "../context";

const toArray = (obj) => (Array.isArray(obj) ? obj : obj ? [obj] : []);
const getFieldValue = (name, fields = []) =>
  fields.find((f) => toArray(f.name).includes(name))?.value;

const Login = () => {
  const { headers, updateHeaders, isAuthenticated } = useHeaders();
  const [form] = Form.useForm();
  const [fields = [], setFields] = useState([
    {
      name: ["grant_type"],
      value: "client_credentials",
    },
    {
      name: ["username"],
      value: null,
    },
    {
      name: ["password"],
      value: null,
    },

    {
      name: ["client_id"],
      value: null,
    },
    {
      name: ["client_secret"],
      value: null,
    },
  ]);

  const onChange = useCallback((newFields) => {
    setFields(newFields);
  }, []);

  const onFinish = async (values) => {
    try {
      const res = await getToken(values);
      const token = res?.access_token;
      const error = res?.error_description || "Please try again later";

      if (!token) {
        notification.open({
          type: "error",
          message: "Error",
          description: error,
        });
      }

      if (isAuthenticated && token) {
        const newData = [...headers];
        const index = newData.findIndex((item) => item.key === "authorization");
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...{
            key: "authorization",
            value: `Bearer ${token}`,
          },
        });
        updateHeaders(newData);
        notification.open({
          type: "info",
          message: "Info",
          description: "Token updated",
        });
      }

      if (!isAuthenticated && token) {
        updateHeaders([
          ...headers,
          {
            key: "authorization",
            value: `Bearer ${token}`,
          },
        ]);
        notification.open({
          type: "success",
          message: "Success",
          description: "Successfully acquired access token",
        });
      }
    } catch (e) {
      notification.open({
        type: "error",
        message: "Error",
        description: "Failed to connect",
      });
    }
  };

  return (
    <Form
      form={form}
      name="global_state"
      layout="inline"
      fields={fields}
      onFieldsChange={(_, newFields) => {
        onChange(newFields);
      }}
      className="login-form"
      onFinish={onFinish}
    >
      <Form.Item name="grant_type">
        <Select
          placeholder="Select grant type"
          defaultValue="client_credentials"
        >
          <Select.Option value="password">Password</Select.Option>
          <Select.Option value="client_credentials">
            Client credentials
          </Select.Option>
        </Select>
      </Form.Item>
      {getFieldValue("grant_type", fields) === "password" && (
        <>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
        </>
      )}
      {getFieldValue("grant_type", fields) === "client_credentials" && (
        <>
          <Form.Item
            name="client_id"
            rules={[
              { required: true, message: "Please input your client id!" },
            ]}
          >
            <Input placeholder="Client id" />
          </Form.Item>

          <Form.Item
            name="client_secret"
            rules={[
              { required: true, message: "Please input your client secret!" },
            ]}
          >
            <Input type="password" placeholder="Client secret" />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Authenticate
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
