import {
  useCallback,
  useState,
  useMemo,
  createContext,
  useContext,
} from "react";

const Headers = createContext();

export const useHeaders = () => useContext(Headers);

export const HeadersContext = ({ children }) => {
  const [headers, setHeaders] = useState([
    { key: "Content-Type", value: "application/json" },
  ]);

  const updateHeaders = useCallback((newHeaders) => {
    setHeaders(newHeaders);
  }, []);

  const isAuthenticated = useMemo(
    () => headers?.filter(({ key }) => key === "authorization")?.length > 0,
    [headers]
  );

  const headerProps = useMemo(
    () => ({ headers, updateHeaders, isAuthenticated }),
    [headers, updateHeaders, isAuthenticated]
  );

  return <Headers.Provider value={headerProps}>{children}</Headers.Provider>;
};
