import { Layout, Collapse, Input } from "antd";
import { useIntrospection } from "./hooks";
import Login from "./components/Login";
import Headers from "./components/Headers";
import Graphiql from "./components/Graphiql";
import logoIcon from "./assets/intelligenciaLogo.svg";
import config from "./config";

const App = () => {
  const { introspecting, error } = useIntrospection();

  if (!introspecting && error) {
    <h1>Schema Introspection Error</h1>;
  }

  return (
    <>
      <Layout.Header>
        <img src={logoIcon} alt="Intelligencia Logo" />
      </Layout.Header>
      <Collapse style={{ marginBottom: 15 }} defaultActiveKey={["credentials"]}>
        <Collapse.Panel header="GraphQL Endpoint" key="endpoint">
          <Input
            disabled
            addonBefore="POST"
            defaultValue={config.graphql.endpoint}
          />
        </Collapse.Panel>
        <Collapse.Panel header="Request Credentials" key="credentials">
          <Login />
        </Collapse.Panel>
        <Collapse.Panel header="Request Headers" key="headers">
          <Headers />
        </Collapse.Panel>
      </Collapse>
      <Graphiql />
    </>
  );
};

export default App;
